.badge-con {
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  padding: 20px;
  border-radius: 10px;
  min-height: 428px;
  border: 0.921px solid rgba(0, 0, 0, 0.05);
  transition: border 0.5s ease;
  background: rgba(255, 255, 255, 0.35);
}
.badge-con:hover {
  border: 0.921px solid #003cff;
  box-shadow: 0px 3.685px 114.249px 9.214px rgba(0, 0, 0, 0.04);
}
.badge-con h4,
.badge-con h5 {
  color: #3e4555;
  font-size: 20px;
}
.badge-con img {
  height: 240px;
  width: 208px;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .badge-con img {
    /* width: 100%; */
    height: 100%;
  }
  h4 {
    font-size: 20px;
    margin-top: 10px !important;
  }
  p {
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px !important;
  }
  h5 {
    font-size: 20px;
  }
}
@media screen and (max-width: 425px) {
  .badge-con img {
    width: 100%;
  }
  h4 {
    font-size: 15px;
    margin-top: 10px !important;
  }
  p {
    font-size: 10px;
    /* line-height: 12px; */
    margin-top: 10px !important;
  }
  h5 {
    font-size: 15px;
  }
}
