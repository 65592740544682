section.MerchantBanner {
  background-image: url(../../../../public/img/Banner/Banner1.png);
}
section.HowBanner {
  background-image: url(../../../../public/img/Banner/Banner2.png);
}
section.AboutBanner {
  background-image: url(../../../../public/img/Banner/Banner3.png);
}
section.BlogBanner {
  background-image: url(../../../../public/img/Banner/Banner4.png);
}
section.VenueBanner {
  background-image: url(../../../../public/img/Banner/Banner5.png);
}
section.Banner {
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  position: relative;
  padding: 0px 20px;
  text-align: center;
}
section.Banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Adjust the color and opacity here */
}
section.Banner h2,
section.Banner h6 {
  color: #fff;
}

