@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body {
  font-family: "Inter", sans-serif;
}
#root {
  overflow: hidden;
}
/* .navbar {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0) !important;
} */
/* .nav-link {
  color: #6b7589 !important;
  font-size: 14.8px !important;
  font-family: "Inter", sans-serif !important;
  font-weight: 600 !important;
  line-height: 16.92px !important;
  justify-content: center !important;
  align-items: center !important;
  display: inline-flex !important;
} */
.dropdown {
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
}
.header-button {
  padding-top: 12.16px;
  padding-bottom: 13.22px;
  padding-left: 16.92px;
  padding-right: 16.32px;
  background: #cd1127;
  border-radius: 8.46px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  border: none;
  color: white;
  font-size: 14.8px;
  font-family: "Inter", sans-serif !important;
  font-weight: 600;
  line-height: 16.92px;
  margin-left: 30px;
}
.main-header {
  /* display: flex;
  flex-direction: column;*/
  height: 100vh;
  /* height: 700px; */
}
.HeroUpdate {
  background-color: #000;
  background-image: url(/public/img/heroback.png);
  background-position: 0 0;
  background-repeat: no-repeat;
}
.HeroUpdate .Hero-Content {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  width: -webkit-fill-available;
  position: relative;
}
.HeroUpdate .Hero-Content .Content {
  max-width: 1020px;
  margin-top: 200px;
}
.HeroUpdate .Hero-Content .banner img {
  width: 100%;
  position: relative;
  top: -200px;
}
.HeroUpdate .Hero-Content .join {
  position: absolute;
  right: 10%;
  bottom: 15%;
}

.HeroUpdate .Hero-Content .Content h4 {
  font-family: "Roboto";
  font-size: 55px;
  color: #fff;
  font-weight: 700;
  line-height: 60.5px;
  margin-bottom: 30px;
}
.HeroUpdate .Hero-Content .Content p {
  font-family: "Roboto";
  font-size: 18.9px;
  font-weight: 400;
  margin-bottom: 30px;
  line-height: 28.35px;
  color: #fff;
}

.HeroUpdate .bg-btn {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 16.3px;
  padding-bottom: 15.3px;
  background-color: #cd1127;

  background: linear-gradient(to right, #fff 50%, #cd1127 50%);
  background-size: 201% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;

  /* border: 1px solid #cd1127; */
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 10.5px;
  display: inline-flex;
  text-align: center;
  color: white;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 700;
  line-height: 24px;
}
.HeroUpdate .bg-btn:hover {
  /* background-color: #fff; */
  color: #000;

  background-position: left bottom;
}
.HeroUpdate .outline-btn {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 14.8px;
  padding-bottom: 14.8px;
  border-radius: 4px;
  border: none;
  justify-content: center;
  align-items: center;
  gap: 10.5px;
  border: 1px solid #fff;
  display: inline-flex;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 700;
  line-height: 24px;
  background: linear-gradient(to right, #cd1127 50%, Transparent 50%);
  background-size: 201% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
}
.HeroUpdate .outline-btn:hover {
  /* background-color: #fe0000; */
  color: #fff;
  background-position: left bottom;
}
.HeroUpdate .hero-bottom-content {
  align-items: end;
  bottom: 45px;
  display: flex;
  justify-content: center;
  max-width: 1600px;
  padding: 0 40px;
  position: absolute;
  width: 100%;
  z-index: 9;
}
.HeroUpdate .hero-bottom-content ul {
  display: flex;
  gap: 20px;
}
.HeroUpdate .hero-bottom-content ul {
  letter-spacing: 8px;
}
.HeroUpdate .hero-bottom-content ul li {
  color: #fff;
}
.HeroUpdate .hero-bottom-content ul li:hover {
  color: #cd1127;
  cursor: pointer;
  -webkit-transition: color 200ms linear;
  -ms-transition: color 200ms linear;
  transition: color 200ms linear;
}
.hero {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  place-items: center;
  height: 100%;
  background-image: url(../../../public/img/bgc.png);
  background-repeat: no-repeat;
  /* background-position: top left; */
  /* background-size: 27%; */
  background-position: -45% -293px;
  background-size: 53%;
}
.hero .row {
  align-items: center;
}
/* .main-header .hero .content {
  max-width: 800px;
} */
.hero .banner img {
  /* max-width: 80%; */
  width: 100%;
}
.hero h1 {
  color: #1d2130;
  font-size: 50px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}
.hero h2 {
  opacity: 0.87;
  color: #080e24;
  font-size: 37px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 55.5px;
}
.hero p {
  opacity: 0.87;
  color: #1d2130;
  font-size: 18.9px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 28.35px;
}
.hero .bg-btn {
  padding-left: 42px;
  padding-right: 42px;
  padding-top: 16.8px;
  padding-bottom: 16.8px;
  background-color: #fe0000;
  border: 2.1px solid #fe0000;
  border-radius: 12.6px;
  justify-content: center;
  align-items: center;
  gap: 10.5px;
  display: inline-flex;
  text-align: center;
  color: white;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 24px;
  -webkit-transition: background-color 0.4s ease-out;
  -moz-transition: background-color 0.4s ease-out;
  -o-transition: background-color 0.4s ease-out;
  transition: background-color 0.4s ease-out;
}
.hero .bg-btn:hover {
  background-color: #fff;
  color: #000;
  /* border: 2.1px solid #fff; */
}
.hero .outline-btn {
  padding-left: 42px;
  padding-right: 42px;
  padding-top: 16.8px;
  padding-bottom: 16.8px;
  border-radius: 12.6px;
  border: 2.1px #e0e4fc solid;
  justify-content: center;
  align-items: center;
  gap: 10.5px;
  display: inline-flex;
  text-align: center;
  color: #fe0000;
  font-size: 16.8px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 25.2px;
  background-color: white;
  -webkit-transition: background-color 0.4s ease-out;
  -moz-transition: background-color 0.4s ease-out;
  -o-transition: background-color 0.4s ease-out;
  transition: background-color 0.4s ease-out;
}
.hero .outline-btn:hover {
  background-color: #fe0000;
  color: #fff;
}

.group-btn {
  display: flex;
  align-items: center;
  gap: 20px;
  z-index: 9;
}
.play-find {
  background-color: black;
  padding: 15px 0px;
}
.play-find h2 {
  color: white;
  font-size: 58.8px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 64.68px;
}
.play-find p {
  opacity: 0.87;
  color: white;
  font-size: 18.9px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 28.35px;
}
.talk {
  background-image: url(../../../public/img/bg.png);
  background-size: 61%;
  background-repeat: no-repeat;
  background-position: top;
}
.talk h1 {
  color: white;
  font-size: 125.23px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 137.75px;
  text-align: center;
}
.inner-community {
  /* background-image: url(/images/2151021342\ 1.png);
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 3; */
  position: relative;
  background: linear-gradient(
    324deg,
    rgba(254, 0, 0, 0.4) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  border-radius: 48.98px;
}
.community {
  padding: 55px 0px;
  position: relative;
}
/* .community .mockups {
  margin-top: -100px;
} */
.community h2 {
  color: white;
  font-size: 34px;
  font-family: Roboto;
  font-weight: 700;
  margin-bottom: 0px;
  margin-top: 25px;
  line-height: 68.57px;
}
.apps {
  display: flex;
  align-items: center;
  padding-top: 20.73px;
  padding-bottom: 20.84px;
  padding-left: 18.94px;
  padding-right: 18.37px;
  border-radius: 24.49px;
  border: 1.22px rgba(255, 255, 255, 0.3) solid;
  width: fit-content;
  gap: 15px;
}
.apps h4 {
  color: white;
  font-size: 17.14px;
  font-family: Inter;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 20.57px;
  letter-spacing: 1.22px;
}
.apps h3 {
  color: white;
  font-size: 29.39px;
  font-family: Inter;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 35.27px;
  letter-spacing: 1.22px;
}
.apps span {
  color: rgba(255, 255, 255, 0.6);
  font-size: 15.92px;
  font-family: Inter;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 19.1px;
  letter-spacing: 1.47px;
}
.ExploreFooter {
  background-color: #000;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding: 20px 0px;
}
.ExploreFooter a {
  color: #fff;
  position: relative;
  opacity: 0.87;
  font-size: 18.9px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 28.35px;
}

.ExploreFooter .footer-join-wrapper p {
  color: #fff;
  opacity: 0.87;
  font-size: 18.9px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 28.35px;
  margin-right: 25px !important;
}
.ExploreFooter .footer-join-wrapper a {
  background-color: #cd1127;
  padding: 15px;
  border-radius: 10px;
}
.explore .arrow {
  position: absolute;
  top: 55%;
  right: -5%;
  transform: translate(-50%, -50%);
  transform: rotate(270deg);
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: -15px -12px -12px 0;
}
.arrow span {
  display: block;
  width: 10px;
  height: 10px;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

.offer-banner-wrapper {
  background-color: #000;
  /* height: 50vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  background-image: url(/public/img/shadow.png);
  background-position: bottom;
  background-repeat: no-repeat;
}
.offer-banner-inner {
  border-radius: 48.98px;
  padding: 50px 0px;
}

.offer-banner-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.offer-banner-img img {
  width: 80%;
  /* height: 80%; */
}
.offer-banner-text1 h5 {
  text-align: left;
  font-size: 3em;
  font-weight: 600;
  letter-spacing: 0px;
  font-family: "Roboto";
  color: #fff;
  opacity: 1;
  margin: 0;
}

.offer-banner-text1 h6 {
  text-align: left;
  font-size: 2em;
  color: #fff;
  font-family: "Roboto";
  font-weight: 700;
  letter-spacing: 0px;
  margin: 0;
}

.offer-banner-text1 p {
  text-align: left;
  font-size: 2em;
  font-family: "Roboto";
  font-weight: 700;
  letter-spacing: 0px;
  opacity: 1;
  color: #fff;
  margin: 0;
}

.offer-banner-text1 p span {
  color: #fff;
}

.offer-banner-part2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.offer-banner-text2 {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  width: 80%;
  margin-left: 50px;
}

.offer-banner-part2 .offer-banner-text2 h5 {
  text-align: left;
  font-size: 3em;
  letter-spacing: 0px;
  font-weight: 600;
  color: #fff;
  margin: 0;
  font-family: "Roboto";
}

.offer-banner-part2 .offer-banner-text2 h5 span {
  color: #fff;
}

.offer-banner-part2 .offer-banner-text2 p {
  text-align: left;
  font-size: 1.2em;
  letter-spacing: 0px;
  font-weight: 600;
  color: #fff;
  margin: 0;
  font-family: "Roboto", sans-serif;
}

.offer-divider {
  border-right: 1px solid rgba(149, 149, 149, 0.82);
  height: 200px;
  margin: 0 -5px;
}

.offer-banner-img-content-banner {
}

.offer-banner-img-content-banner p {
}

.offer-banner-qr-img {
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.offer-banner-qr-img img {
  width: 75%;
}

.offer-banner-part2-mobile {
  display: none;
}

.offer-banner-part2-whole-wrapper {
  display: flex;
  justify-content: end;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .offer-banner-part1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .offer-banner-img {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .offer-banner-img img {
    width: 90%;
    /* height: 80%; */
  }

  .offer-banner-text1 {
  }

  .offer-banner-text1 h5 {
    text-align: left;
    font-size: 2em;
    font-weight: 700;
    letter-spacing: 0px;
    font-family: "Montserrat", sans-serif;
    color: #000000;
    opacity: 1;
    margin: 0;
    font-style: italic;
  }

  .offer-banner-text1 h6 {
    text-align: left;
    font-size: 1.5em;
    /* font-style: italic; */
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    letter-spacing: 0px;
    opacity: 1;
    margin: 0;
  }

  .offer-banner-text1 p {
    text-align: left;
    font-size: 1.5em;
    font-style: italic;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    letter-spacing: 0px;
    opacity: 1;
    margin: 0;
  }

  .offer-banner-part2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .offer-banner-text2 {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    width: 80%;
    margin-left: 50px;
  }

  .offer-divider {
    border-bottom: 1px solid rgba(149, 149, 149, 0.82);
    width: 100%;
    margin: 0 -5px;
    border-right: none;
    height: 30px;
  }

  .offer-banner-qr-img {
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .offer-banner-qr-img img {
    width: 75%;
  }

  .offer-banner-part2-whole-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .offer-banner-part2-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 15px;
  }

  .offer-banner-part2-mobile h5 {
    margin: 0;
    font-size: 2em;
    letter-spacing: 0px;
    font-weight: 600;
    color: #000000;
    opacity: 1;
    font-family: "Montserrat", sans-serif;
  }

  .offer-banner-part2-mobile h5 span {
    color: #3eb661;
    font-size: 1.5em;
  }

  .offer-banner-part2-mobile p {
    margin: 0;
    font-size: 1em;
    letter-spacing: 0px;
    font-weight: 600;
    color: #000000;
    opacity: 1;
    font-family: "Montserrat", sans-serif;
    margin-top: 10px;
  }

  .offer-banner-part2-mobile .download-text {
    margin: 0;
    font-size: 0.8em;
    letter-spacing: 0px;
    font-weight: 500;
    color: #000000;
    opacity: 1;
    font-family: "Montserrat", sans-serif;
    margin-top: 10px;
  }

  .offer-banner-part2-mobile .download-container {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .offer-banner-part2-mobile .download-container a {
    text-decoration: none;
    width: 100%;
  }

  .offer-banner-part2-mobile .download-container a img {
    margin: 0 5px 10px 5px;
    width: 80%;
  }
}
.footer {
  background: #340000;
  padding: 57px 0px;
}
.footer ul {
  padding: 0px !important;
  margin: 0px !important;
}
.footer ul li {
  list-style-type: none;
  opacity: 0.6;
  color: white;
  font-size: 16.8px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 25.2px;
  margin-bottom: 10px;
}
.footer label {
  color: white;
  font-size: 18.9px;
  font-family: Roboto;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 28.35px;
  margin-bottom: 10px;
}
.footer h2 {
  color: white;
  font-size: 18.9px;
  font-family: Roboto;
  font-weight: 600;
  /* line-height: 28.35px; */
}
.input-group {
  opacity: 0.4;
  /* background: black; */
  border-radius: 8.4px;
  padding: 15px;
}
.input-group input {
  background-color: transparent;
  border: none;
  outline: none;
}
.input-group input:focus-visible {
  outline: none;
  border: none;
}
.input-group input::placeholder {
  opacity: 0.8;
  color: white;
  font-size: 16.8px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 25.2px;
}
.video-section {
  padding: 90px 0px;
}
.video-section .top-p {
  opacity: 0.87;
  color: white !important;
  font-size: 18.9px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 28.35px;
  letter-spacing: 10px;
  position: absolute;
  top: -12px;
  left: 100px;
}
.video-section h2 {
  color: white;
  font-size: 60px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 66px;
  position: relative;
  z-index: 1;
}
.video-section h3 {
  color: white;
  font-size: 60px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 66px;
  position: relative;
  z-index: 1;
}
.video-section svg {
  margin-right: 15px;
}
.video-section .left {
  position: absolute;
}
.video-section .right {
  position: absolute;
  right: 0px;
  bottom: 0px;
}
.video-section .social {
  position: absolute;
  bottom: -18px;
}
.right-p {
  position: absolute !important;
  right: 82px !important;
  bottom: -28px !important;
  z-index: 2;
  opacity: 0.87;
  color: white !important;
  font-size: 18.9px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 28.35px;
  letter-spacing: 10px;
  text-transform: uppercase;
}
.modal-content {
  background-color: #6b7589 !important;
  border-bottom: none !important;
}
.modal-header {
  background-color: #6b7589 !important;
  border-bottom: none !important;
}
.modal-body {
  background-color: #6b7589 !important;
  border-bottom: none !important;
}
.modal-footer {
  border-top: none !important;
}
.TermsPage {
  margin-bottom: 50px;
}
.TermsPage ul li {
  margin-bottom: 10px;
}
.TermsPage a {
  color: #0d6efd;
}
.number span,
.counter {
  color: #fff !important;
  font-size: 32px;
  font-weight: 600;
}
@media screen and (max-width: 2500px) and (min-width: 1520px) {
  /* .container {
    max-width: 86%;
  } */
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
  .hero {
    background-size: 40%;
  }
  .hero h1 {
    font-size: 50px;
  }
  .hero h2 {
    font-size: 30px;
    line-height: 45.5px;
  }
  .hero .bg-btn {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10.8px;
    padding-bottom: 10.8px;
  }
  .hero .outline-btn {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10.8px;
    padding-bottom: 10.8px;
  }
}

@media screen and (max-width: 991px) {
  .FirstNav .Book,
  .FirstNav .live {
    width: 100%;
    margin: 0px;
  }
  .dropdown {
    flex-direction: column !important;
  }
  .header-button {
    margin-left: 0px;
  }
  /* .navbar-collapse {
    position: absolute;
    top: 80px;
    background: #cfcfcf;
    width: 100%;
    height: 440px;
    left: 0px;
    margin: auto;
    z-index: 7;
    padding: 0px 21px;
  } */
  .hero {
    background-size: 40%;
  }
  .hero h1 {
    font-size: 50px;
  }
  .hero h2 {
    font-size: 30px;
    line-height: 45.5px;
  }
  .hero .bg-btn {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10.8px;
    padding-bottom: 10.8px;
  }
  .hero .outline-btn {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10.8px;
    padding-bottom: 10.8px;
  }
  .play-find {
    padding: 30px 0px;
  }
  .play-find h2 {
    font-size: 40.8px;
    line-height: 50.68px;
  }
  .play-find p {
    font-size: 16px;
  }
  .talk h1 {
    font-size: 70.23px;
    line-height: 70.75px;
  }
  .apps {
    width: 100%;
  }
  .community h2 {
    font-size: 30px;
    line-height: 48.57px;
  }
  /* .group-gr button {
    padding: 13.8px 8px !important;
    background: white;
    border-radius: 12.6px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 3.5px;
    display: inline-flex;
    color: rgb(205, 17, 39);
    font-size: 10.8px !important;
    font-family: Roboto;
    font-weight: 500;
    line-height: 14.2px !important;
    border: none;
  } */
  .input-group {
    opacity: 0.4;
    /* background: black; */
    border-radius: 8.4px;
    padding: 8px;
  }
  .input-group input::placeholder {
    font-size: 10.8px;
    line-height: 20.2px;
  }
}
@media screen and (max-width: 767px) {
  .right-p {
    display: none;
  }
  .video-section h2 {
    font-size: 30px;
    line-height: 33px;
  }
  .video-section h3 {
    font-size: 30px;
    line-height: 33px;
  }
  .video-section .top-p {
    font-size: 12.9px;
    line-height: 18.35px;
  }
  .mockups {
    width: 100%;
    /* position: relative;
bottom: 300px; */
  }
  .group-btn {
    flex-wrap: wrap;
  }
  .group-btn button {
    width: 100%;
  }
  .talk h1 {
    font-size: 40.23px;
    line-height: 40.75px;
  }
  .community h2 {
    font-size: 25px;
    line-height: 38.57px;
  }
  .apps {
    flex-wrap: wrap;
    justify-content: center;
  }
  .apps h4 {
    font-size: 14.14px;
  }
  .apps h3 {
    font-size: 19.39px;
  }
  .apps span {
    font-size: 12.92px;
  }
  .hero h1 {
    font-size: 30px;
  }
  .hero h2 {
    font-size: 25px !important;
    line-height: 35.5px;
  }
  .container {
    padding: 0px 20px !important;
  }
  /* .community .mockups {
    margin-top: -50px;
  } */
  .play-find h2 {
    font-size: 30.8px;
    line-height: 30.68px;
  }
  .play-find p {
    font-size: 13px;
  }
  .video-image {
    width: 100%;
  }
}
@media screen and (max-width: 1500px) and (min-width: 1199px) {
  .hero {
    background-size: 46%;
  }
}
@media screen and (max-width: 1800px) and (min-width: 1501px) {
  .hero {
    background-size: 55%;
  }
}
/* @media screen and (max-width: 2100px) and (min-width: 1800px) {
  .hero {
    background-size: 45%;
  }
} */
@media screen and (max-width: 2700px) and (min-width: 2001px) {
  .hero .bg-btn {
    padding-left: 80px;
    padding-right: 60px;
    padding-top: 15.8px;
    padding-bottom: 15.8px;
  }
  .hero .outline-btn {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 15.8px;
    padding-bottom: 15.8px;
  }
}
@media only screen and (max-width: 1200px) {
  .benefitCard {
    height: 350px;
  }
  .number {
    min-width: auto;
    max-width: 100%;
  }
}
@media only screen and (max-width: 1100px) {
  .hero .banner img {
    position: absolute;
    right: 0px;
    z-index: -1;
  }
  .HeroUpdate .Hero-Content .Content {
    margin-top: 0px;
  }
  .HeroUpdate .Hero-Content .banner img {
    top: -150px;
  }
  .HeroUpdate .hero-bottom-content ul {
    letter-spacing: 0px;
  }
}
@media only screen and (max-width: 991px) {
  .hero h1 {
    line-height: 43px;
  }
  .hero .banner img {
    top: 140px;
    opacity: 0.5;
  }
  .benefitCard {
    height: auto;
  }
  .HeroUpdate .hero-bottom-content ul {
    gap: 5px;
  }
  .HeroUpdate .Hero-Content .banner img {
    top: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .hero .banner img {
    display: none;
  }
  .hero .content {
    text-align: center;
    text-align: -webkit-center;
    text-align: -moz-center;
  }
  .hero .group-btn {
    justify-content: center;
  }
  .HeroUpdate .Hero-Content {
    justify-content: flex-start;
    margin-top: 100px;
  }
  .HeroUpdate .Hero-Content .join {
    position: static;
  }
  .main-header {
    height: auto;
  }
  .HeroUpdate .hero-bottom-content {
    display: none;
  }
  .HeroUpdate .Hero-Content .banner img {
    margin: 0px 0px 45px 0px;
  }
  .HeroUpdate .bg-btn {
    width: 100%;
  }
  .HeroUpdate .outline-btn {
    width: 100%;
  }
  .play-find img {
    width: 100%;
  }
}
